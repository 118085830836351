import React from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { FetchForm } from "./feed/FetchForm";

export function FeedPage() {
  return (
    <Box w="100%" p="8" bgColor="gray.100">
      <React.Suspense fallback={<Spinner />}>
        <FetchForm />
      </React.Suspense>
    </Box>
  );
}
