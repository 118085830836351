import React, { useEffect, useMemo } from "react";
import { ChakraProvider, CSSReset, Spinner, useToast } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import * as Sentry from "@sentry/react";

import { WebsocketProvider } from "./rpc/websocket";
import { FetchError } from "./rpc/utils";
import { useTodoListener } from "./hooks/todo";
import { useUserListener } from "./hooks/user";
import { AppRoutes } from "./AppRoutes";
import { useAuth, useAuthStore } from "./hooks/authStore";
import { isGrpcError } from "./rpc/error";
import { useFeedListener } from "./hooks/data/feed";
import { DetailedError } from "./hooks/rpc/client";

Sentry.init({
  dsn: "https://fd299f1eb19d98c854ec0160c81981ba@o212040.ingest.sentry.io/4506659622748160",
  environment: process.env.RELEASE ? "production" : "development",
  release: process.env.RELEASE,
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/snaplabs\.com\/api/],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function buildWebsocketUrl(): string {
  const base = process.env.WS_URL ?? "/wsapi";

  if (base === "") {
    return "";
  }
  if (base.startsWith("ws")) {
    return base;
  }

  const loc = window.location;

  return new URL(base, (loc.protocol === "https:" ? "wss://" : "ws://") + loc.host + loc.pathname).toString();
}

const WS_URL = buildWebsocketUrl();

function AuthErrorBoundary({ error }: FallbackProps) {
  // const navigate = useNavigate();
  // const { mutations } = useAuth();
  // const logout = mutations.useLogout();
  const { setToken } = useAuthStore((s) => s);

  useEffect(() => {
    if (error instanceof FetchError && error.code === 401) {
      // logout({
      //   onCompleted() {
      //     navigate("/auth/login");
      //   },
      // });
      setToken(null);
    } else if (error instanceof DetailedError && error.statusCode === 401) {
      // nothing
    } else {
      // Take this to the error boundary
      throw error;
    }
  }, [error, setToken]);

  // toast({
  //   status: "error",
  //   title: "Authentication failed",
  //   isClosable: true,
  //   onCloseComplete() {
  //     logout({
  //       onCompleted() {
  //         navigate("/auth/login");
  //       },
  //     });
  //   },
  // });

  return null;
}

function ClearOnLogout({ queryClient }: { queryClient: QueryClient }) {
  const { token } = useAuth();

  useEffect(() => {
    if (!token) {
      queryClient.clear();
    }
  }, [token, queryClient]);

  useTodoListener();
  useUserListener();
  useFeedListener();

  return null;
}

export default function App(): React.ReactElement {
  const toast = useToast();

  const queryClient = useMemo(() => {
    function onError(error: unknown) {
      if (error instanceof FetchError) {
        if (isGrpcError(error)) {
          const { code } = error.body;
          if (code === "invalid_argument") {
            // Should be handled by forms
            return;
          }

          if (code === "internal") {
            toast({
              title: "Internal error",
              status: "error",
              isClosable: true,
            });

            return;
          }
          if (code === "unauthenticated") {
            // TODO handle
            return;
          }

          // Log it
        }
      }
      if (error instanceof TypeError) {
        // Network issue
        toast({
          status: "error",
          title: "Networking error encountered",
          isClosable: true,
        });

        Sentry.captureMessage("Network error", {
          level: "info",
          tags: { network_error: error.message },
        });
        return;
      }

      // Log this for future understanding
      Sentry.captureException(error);

      // // This is react-query throwing a CancelError
      // const isCancelledError = error && Object.hasOwn(error, "silent");
      // if (isCancelledError) {
      //   return;
      // }
      // eslint-disable-next-line no-console
      console.error(error);

      toast({
        status: "error",
        title: "An unexpected error occured",
        isClosable: true,
      });
    }

    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
        mutations: {
          onError,
        },
      },
    });
  }, [toast]);

  return (
    <ChakraProvider>
      <CSSReset />
      <ErrorBoundary FallbackComponent={AuthErrorBoundary}>
        <QueryClientProvider client={queryClient}>
          <WebsocketProvider url={WS_URL}>
            <React.Suspense fallback={<Spinner />}>
              <ClearOnLogout queryClient={queryClient} />
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </React.Suspense>
          </WebsocketProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ChakraProvider>
  );
}
