import { useEffect } from "react";
import { QueryClient, useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { MutationOptions, QueryData, useFetchClientAuth } from "./rpc/client";

import type { paths } from "../gen/api/todo";
import { MessageTodo, MessageTodoSchema } from "../gen/api/async";
import { useWebsocketUpdates } from "../rpc/websocket";
import { sharedEventCrud } from "./data/shared";
import { must } from "../util/assert";

const CACHE_KEY = ["todos"];

type TodoCache = QueryData<paths["/todo/list"]["post"]>;
export type TodoObject = TodoCache["todos"][0];

function handleEvent(queryClient: QueryClient, event: Omit<MessageTodo, "topic">) {
  queryClient.setQueriesData<TodoCache>({ queryKey: CACHE_KEY }, (old) => ({
    todos: sharedEventCrud(old?.todos, event),
  }));
}

export function useTodos() {
  const client = useFetchClientAuth<paths>();

  return useSuspenseQuery({
    queryKey: CACHE_KEY,
    async queryFn({ signal }): Promise<TodoCache> {
      const { data } = await client.POST("/todo/list", {
        // params: {},
        // body - isn’t used for GET, but needed for other request types
        signal, // allows React Query to cancel request
      });
      // Note: Error throwing handled automatically via middleware
      return must(data);
    },
    staleTime: Infinity,
  });
}

export function useTodoAdd(params?: MutationOptions<paths["/todo/add"]["post"]>) {
  const client = useFetchClientAuth<paths>();
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/todo/add", { body });

      return must(data);
    },
    onSuccess(data) {
      handleEvent(queryClient, { object_id: data.id, action: "create", body: data });
    },
    ...params,
  });
}

export function useTodoDelete(params?: MutationOptions<paths["/todo/delete"]["post"]>) {
  const client = useFetchClientAuth<paths>();
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/todo/delete", { body });

      return must(data);
    },
    // async mutationFn({ body, reactQuery }: UseQueryOptions<paths["/todo/delete"]["post"]>) {
    //   return clientX.POST("/todo/delete", { ...reactQuery, body });
    // },
    onSuccess(_, { body }) {
      handleEvent(queryClient, {
        action: "delete",
        object_id: body.id,
        body: null,
      });
    },
    ...params,
  });
}

export function useTodoListener() {
  const { addListener } = useWebsocketUpdates();
  const queryClient = useQueryClient();

  useEffect(
    () =>
      addListener("todo", (event: unknown) => {
        const data = MessageTodoSchema.parse(event);

        handleEvent(queryClient, data);
      }),
    [queryClient, addListener],
  );
}
