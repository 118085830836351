import React, { useState } from "react";
import { Heading, Box, Flex, FormControl, FormLabel, FormErrorMessage, Button, Input, Spinner } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useFeedApi } from "../../hooks/data/feed";
import { FetchError } from "../../rpc/utils";

const INPUT_STYLE = {
  bg: "white",
  borderColor: "gray.400",
};

type FormFields = {
  url: string;
};

function FeedDisplay({ id }: { id: string }) {
  const { useFetchRecord } = useFeedApi();
  const data = useFetchRecord(id);

  if (!data) {
    return <Spinner />;
  }

  return <div>Title: {data.title}</div>;
}

export function FetchForm() {
  const { useFeedFetch } = useFeedApi();
  const [resultText] = useState("");
  const [loadingFeed, setLoadingFeed] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const callTextApi = useFeedFetch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: "https://techcrunch.com/feed/",
    },
  });

  function onSubmit(data: FormFields) {
    setSubmitting(true);
    callTextApi.mutate(data, {
      onSuccess(result) {
        setLoadingFeed(result.id);
      },
      onError(error) {
        if (error instanceof FetchError) {
          const { code, msg, argument } = error.getInfo();

          if (code === "invalid_argument" && msg && argument) {
            setError(argument as keyof FormFields, { message: msg });
          }
        }
        setSubmitting(false);
      },
      onSettled() {
        setSubmitting(false);
      },
    });
  }

  return (
    <Box w="100%" p="8" bgColor="gray.100">
      <Box w="100%" bgColor="white" p="5">
        <Heading as="h3" size="xl" textColor="gray.800" textAlign="center" fontWeight="light" padding="5">
          Feed Preview
        </Heading>

        <FormControl isInvalid={!!errors.url}>
          <FormLabel>URL</FormLabel>
          <Flex>
            <Input type="text" {...register("url")} {...INPUT_STYLE} />
          </Flex>
          <FormErrorMessage>{errors.url?.message}</FormErrorMessage>
        </FormControl>

        <Flex pt="5" justify="left">
          <Box>{resultText}</Box>
        </Flex>

        <Flex pt="5" justify="right">
          <Button mt={4} colorScheme="teal" isLoading={isSubmitting} type="submit" onClick={handleSubmit(onSubmit)}>
            Send
          </Button>
        </Flex>

        {loadingFeed && <FeedDisplay id={loadingFeed} />}
      </Box>
    </Box>
  );
}
