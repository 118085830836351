import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useWebsocketUpdates } from "../rpc/websocket";
import { useFetchClientAuth, MutationOptions, QueryData } from "./rpc/client";
import type { paths } from "../gen/api/user";
import { MessageUserSchema } from "../gen/api/async";
import { must } from "../util/assert";

const CACHE_KEY = ["user"];

type UserData = QueryData<paths["/user/current"]["post"]>;

export function useUser() {
  const client = useFetchClientAuth<paths>();

  const result = useSuspenseQuery({
    queryKey: CACHE_KEY,
    async queryFn({ signal }): Promise<UserData> {
      const { data } = await client.POST("/user/current", { signal });

      return must(data);
    },
    staleTime: Infinity,
  });

  return result.data;
}

export function useUserUpdate(params?: MutationOptions<paths["/user/update"]["post"]>) {
  const client = useFetchClientAuth<paths>();
  const queryClient = useQueryClient();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/user/update", { body });

      queryClient.setQueryData<UserData>(CACHE_KEY, data);

      return must(data);
    },
    ...params,
  });
}

export function useUserListener() {
  const queryClient = useQueryClient();
  const { addListener } = useWebsocketUpdates();

  useEffect(
    () =>
      addListener("user", (event: unknown) => {
        const data = MessageUserSchema.parse(event);

        if (data.body) {
          queryClient.setQueryData<UserData>(CACHE_KEY, data.body);
        }
      }),
    [addListener, queryClient],
  );
}
