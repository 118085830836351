import React from "react";
import { BsListOl } from "react-icons/bs";
import { WithEditor } from "../types";
import ToolbarButton from "./ToolbarButton";

export default function OrderedListTool({ editor }: WithEditor) {
  return (
    <ToolbarButton
      label="Numbered list"
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      isActive={editor.isActive("orderedList")}
      icon={<BsListOl />}
      disabled={!editor.isEditable}
    />
  );
}
