import { useMutation } from "@tanstack/react-query";
import { useAuth } from "./authStore";

import { MutationOptions, useFetchClient } from "./rpc/client";
import type { paths } from "../gen/api/auth";
import { must } from "../util/assert";

export function useAuthRegister(params?: MutationOptions<paths["/auth/register"]["post"]>) {
  const { setToken } = useAuth();
  const client = useFetchClient<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/register", { body });
      const mdata = must(data);

      setToken(mdata.token.access_token);

      return mdata;
    },
    ...params,
  });
}

export function useAuthEmailConfirm(params?: MutationOptions<paths["/auth/verify_email"]["post"]>) {
  const client = useFetchClient<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/verify_email", { body });
      const mdata = must(data);

      return mdata;
    },
    ...params,
  });
}

export function useAuthLogin(params?: MutationOptions<paths["/auth/authenticate"]["post"]>) {
  const client = useFetchClient<paths>();
  const { setToken } = useAuth();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/authenticate", { body });
      const mdata = must(data);

      if (mdata.token) {
        setToken(mdata.token.access_token);
      }

      return mdata;
    },
    ...params,
  });
}

export function useAuthLogout({ onSuccess }: { onSuccess?: () => void }) {
  // TODO: this should invalidate the JWT
  const { setToken } = useAuth();

  return () => {
    setToken(null);
    onSuccess?.();
  };
}

export function useAuthRecoverSend(params?: MutationOptions<paths["/auth/recover_send"]["post"]>) {
  const client = useFetchClient<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/recover_send", { body });
      const mdata = must(data);

      return mdata;
    },
    ...params,
  });
}

export function useAuthRecoverVerify(params?: MutationOptions<paths["/auth/recover_verify"]["post"]>) {
  const client = useFetchClient<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/recover_verify", { body });
      const mdata = must(data);

      return mdata;
    },
    ...params,
  });
}

export function useAuthRecoverUpdate(params?: MutationOptions<paths["/auth/recover_update"]["post"]>) {
  const client = useFetchClient<paths>();
  const { setToken } = useAuth();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/recover_update", { body });
      const mdata = must(data);

      setToken(mdata.token.access_token);

      return mdata;
    },
    ...params,
  });
}

export function useAuthOauthRedirect(params?: MutationOptions<paths["/auth/oauth_url"]["post"]>) {
  const client = useFetchClient<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/oauth_url", { body });
      const mdata = must(data);

      return mdata;
    },
    ...params,
  });
}

export function useAuthOauthLogin(params?: MutationOptions<paths["/auth/oauth_login"]["post"]>) {
  const client = useFetchClient<paths>();
  const { setToken } = useAuth();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/auth/oauth_login", { body });

      const mdata = must(data);

      setToken(mdata.token.access_token);

      return mdata;
    },
    ...params,
  });
}
