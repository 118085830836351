import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Text,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Box,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAuthRecoverUpdate, useAuthRecoverVerify } from "../../hooks/auth";
import { assert } from "../../util/assert";
import { extractFieldErrors } from "../../rpc/error";

type FormFields = {
  password: string;
};

function Verify({ loading, success }: { loading: boolean; success: boolean }): JSX.Element | null {
  if (loading) {
    return <Spinner />;
  }
  if (success) {
    return null;
  }

  return <Alert>It appears that this link has already been used</Alert>;
}

export function AuthRecoveryResetPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormFields>();
  const { user_id: userId, token } = useParams<{ user_id: string; token: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [completed, setCompleted] = useState(false);
  const recoverVerify = useAuthRecoverVerify({
    onSettled() {
      setVerified(true);
    },
    onError() {
      // todo should check the error type
      setVerified(false);
    },
  });
  const recoverUpdate = useAuthRecoverUpdate({
    onSuccess() {
      setCompleted(true);
      const query = new URLSearchParams(search);
      navigate(query.get("next") ?? "/", { replace: true });
    },
    onError(error) {
      const fields = extractFieldErrors(error);

      if (fields) {
        const formFields = ["password"] as const;
        formFields.forEach((key) => {
          const message = fields[key];
          if (message) {
            setError(key, { message });
          }
        });
      }
    },
  });

  assert(userId && token);

  useEffect(() => {
    recoverVerify.mutate({ body: { user_id: userId, token } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, token]);

  function onSubmit({ password }: { password: string }) {
    assert(userId && token);

    recoverUpdate.mutate({ body: { user_id: userId, token, password } });
  }

  let body;
  if (!verified) {
    body = <Verify loading={recoverVerify.isPending} success={verified} />;
  } else if (!completed) {
    body = (
      <FormControl id="password" isInvalid={!!errors.password}>
        <FormLabel>Enter your new password</FormLabel>
        <Input
          type="password"
          {...register("password", {
            required: {
              value: true,
              message: "Please provide a password",
            },
            minLength: {
              value: 8,
              message: "Passwords must have 8 characters",
            },
          })}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
    );
  } else {
    body = (
      <Alert status="success">
        <AlertIcon />
        Instructions have been sent, please check your email.
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack minH="100vh" direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align="center" justify="center">
          <Stack spacing={8} w="full" maxW="md">
            <Heading fontSize="2xl">Reset your password?</Heading>
            <Text>
              RECOVERY RESET! Entere the email address you signed up with, and we will send instructions on how to reset
              your password
            </Text>
            {body}
            <Stack spacing={6}>
              {!completed && verified && (
                <Button
                  isLoading={recoverUpdate.isPending}
                  disabled={completed}
                  type="submit"
                  colorScheme="blue"
                  variant="solid"
                >
                  Reset your password
                </Button>
              )}
              <Stack direction={{ base: "column", sm: "row" }} align="start" justify="space-between">
                <Link as={RouterLink} to="/auth/login" color="blue.500">
                  Sign-in
                </Link>
                <Link as={RouterLink} to="/auth/recover/send" color="blue.500">
                  Forgot password?
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Box
            width="100%"
            bgImage="url(https://source.unsplash.com/random)"
            bgRepeat="no-repeat"
            bgPosition="center"
            // bgColor={(t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900])},
            bgSize="cover"
          />
        </Flex>
      </Stack>
    </form>
  );
}
