import * as z from "zod";


export const ActionSchema = z.enum([
    "create",
    "delete",
    "update",
]);
export type Action = z.infer<typeof ActionSchema>;


export const MessageUserTopicSchema = z.enum([
    "user",
]);
export type MessageUserTopic = z.infer<typeof MessageUserTopicSchema>;


export const MessageTodoTopicSchema = z.enum([
    "todo",
]);
export type MessageTodoTopic = z.infer<typeof MessageTodoTopicSchema>;

export const MessageUserBodySchema = z.object({
    "avatar_url": z.union([z.null(), z.string()]).optional(),
    "email": z.string(),
    "id": z.string(),
    "name": z.string(),
});
export type MessageUserBody = z.infer<typeof MessageUserBodySchema>;

export const MessageTodoBodySchema = z.object({
    "id": z.string(),
    "task": z.string(),
});
export type MessageTodoBody = z.infer<typeof MessageTodoBodySchema>;

export const MessageUserSchema = z.object({
    "action": ActionSchema,
    "body": z.union([MessageUserBodySchema, z.null()]),
    "object_id": z.string(),
    "topic": MessageUserTopicSchema,
});
export type MessageUser = z.infer<typeof MessageUserSchema>;

export const MessageTodoSchema = z.object({
    "action": ActionSchema,
    "body": z.union([MessageTodoBodySchema, z.null()]),
    "object_id": z.string(),
    "topic": MessageTodoTopicSchema,
});
export type MessageTodo = z.infer<typeof MessageTodoSchema>;
