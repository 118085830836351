import { z } from "zod";
import { DetailedError } from "../hooks/rpc/client";

export const HttpError = z.object({
  code: z.number(),
  body: z.object({}),
});

export const GrpcError = z.object({
  code: z.union([
    z.literal("ok"),
    z.literal("cancelled"),
    z.literal("unknown"),
    z.literal("invalid_argument"),
    z.literal("deadline_exceeded"),
    z.literal("not_found"),
    z.literal("already_exists"),
    z.literal("permission_denied"),
    z.literal("unauthenticated"),
    z.literal("resource_exhausted"),
    z.literal("failed_precondition"),
    z.literal("aborted"),
    z.literal("out_of_range"),
    z.literal("unimplemented"),
    z.literal("internal"),
    z.literal("unavailable"),
    z.literal("data_loss"),
  ]),
  message: z.string(),
  details: z.optional(z.array(z.unknown())),
});

export const GrpcBadRequest = z.object({
  type: z.literal("google.rpc.BadRequest"),
  value: z.string(),
  debug: z.object({
    "@type": z.literal("type.googleapis.com/google.rpc.BadRequest"),
    fieldViolations: z.array(
      z.object({
        field: z.string(),
        description: z.string(),
      }),
    ),
  }),
});

export const GrpcInvalidArgument = z.object({
  code: z.literal("invalid_argument"),
  message: z.string(),
  details: z.optional(z.array(GrpcBadRequest)),
});

export function isHttpError(data: unknown): data is z.infer<typeof HttpError> {
  const result = HttpError.safeParse(data);

  return result.success;
}

export function isGrpcError(data: unknown): data is { body: z.infer<typeof GrpcError> } {
  if (!isHttpError(data)) {
    return false;
  }

  const result = GrpcError.safeParse(data.body);

  return result.success;
}

export function isGrpcFieldError(data: unknown): data is { body: z.infer<typeof GrpcInvalidArgument> } {
  if (!isHttpError(data)) {
    return false;
  }

  const result = GrpcInvalidArgument.safeParse(data.body);

  return result.success;
}

export function extractFieldErrors(data: unknown): Record<string, string> | null {
  if (data instanceof DetailedError) {
    if (data.code !== "invalid_argument" || !data.details?.length) {
      return null;
    }

    return Object.fromEntries(data.details.map(({ field, description }) => [field, description]));
  }

  if (!isGrpcFieldError(data)) {
    return null;
  }

  const result: Record<string, string> = {};

  data.body.details
    ?.filter((value) => value.debug.fieldViolations?.length !== 0)
    .forEach((value) => {
      const entry = value.debug.fieldViolations;
      if (entry.length !== 0 && entry[0] !== undefined) {
        result[entry[0].field] = entry[0].description;
      }
    });

  if (Object.keys(result).length === 0) {
    return null;
  }

  return result;

  // const foo = {
  //   code: "invalid_argument",
  //   message: "bad format",
  //   details: [
  //     {
  //       type: "google.rpc.BadRequest",
  //       value: "ChMKBWVtYWlsEgpiYWQgZm9ybWF0",
  //       debug: {
  //         "@type": "type.googleapis.com/google.rpc.BadRequest",
  //         fieldViolations: [{ field: "email", description: "bad format" }],
  //       },
  //     },
  //   ],
  // };
}
