/* eslint-disable max-classes-per-file */
import { z } from "zod";
import { useMemo } from "react";
import createClient, { FetchResponse, Middleware, ParamsOption, RequestBodyOption } from "openapi-fetch";
import { UseMutationOptions, UseQueryOptions, UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useAuth } from "../authStore";

export type QueryData<T> = Exclude<FetchResponse<T, "json", "application/json">["data"], undefined>;

export type MutationOptions<
  T extends object,
  TData = Exclude<FetchResponse<T, "json", "application/json">["data"], undefined>,
  TError = Exclude<FetchResponse<T, "json", "application/json">["error"], undefined>,
  TVariables = RequestBodyOption<T> & ParamsOption<T>,
  TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext>;

export type QueryOptions<
  T extends object,
  TQueryData = RequestBodyOption<T> & ParamsOption<T>,
  TData = Exclude<FetchResponse<T, "json", "application/json">["data"], undefined>,
  TError = Exclude<FetchResponse<T, "json", "application/json">["error"], undefined>,
> = UseQueryOptions<TQueryData, TError, TData> & { signal: AbortSignal };

export type SuspenseQueryOptions<
  T extends object,
  TData = Exclude<FetchResponse<T, "json", "application/json">["data"], undefined>,
  // TQueryData = RequestBodyOption<T> & ParamsOption<T>,
  TError = Exclude<FetchResponse<T, "json", "application/json">["error"], undefined>,
  // TQueryKey extends QueryKey = ReadonlyArray<string>,
> = UseSuspenseQueryOptions<TData, TError>;

// interface UseQueryOptions<TQueryFnData = unknown, TError = DefaultError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey> extends OmitKeyof<UseBaseQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'suspense'> {

export type OldQueryOptions<T> = ParamsOption<T> &
  RequestBodyOption<T> & {
    // add your custom options here
    reactQuery?: {
      enabled: boolean; // Note: React Query type’s inference is difficult to apply automatically, hence manual option passing here
      // add other React Query options as needed
    };
  };

export class HttpError extends Error {}

const DetailedErrorSchema = z.object({
  code: z.union([
    z.literal("ok"),
    z.literal("cancelled"),
    z.literal("unknown"),
    z.literal("invalid_argument"),
    z.literal("deadline_exceeded"),
    z.literal("not_found"),
    z.literal("already_exists"),
    z.literal("permission_denied"),
    z.literal("unauthenticated"),
    z.literal("resource_exhausted"),
    z.literal("failed_precondition"),
    z.literal("aborted"),
    z.literal("out_of_range"),
    z.literal("unimplemented"),
    z.literal("internal"),
    z.literal("unavailable"),
    z.literal("data_loss"),
  ]),
  message: z.string(),
  details: z.optional(
    z.array(
      z.object({
        field: z.string(),
        description: z.string(),
      }),
    ),
  ),
});

export const BASE_URL = process.env.BASE_URL ?? "NOIT_SET";

type DetailedErrorType = z.infer<typeof DetailedErrorSchema>;

export class DetailedError extends Error {
  statusCode: number;
  code: DetailedErrorType["code"];
  details: DetailedErrorType["details"];

  constructor(statusCode: number, detailed: DetailedErrorType) {
    super(detailed.message);

    this.statusCode = statusCode;
    this.code = detailed.code;
    this.details = detailed.details;
  }
}

function createError(statusCode: number, body: unknown) {
  const result = DetailedErrorSchema.safeParse(body);

  if (!result.success) {
    return new HttpError(`HTTP Error status=${statusCode} body=${JSON.stringify(body)}`);
  }

  return new DetailedError(statusCode, result.data);
}

//
//

function throwOnError(setToken?: (token: string | null) => void): Middleware {
  return {
    async onResponse(res) {
      if (res.status >= 400) {
        if (res.status === 401 || res.status === 403) {
          setToken?.(null);
        }

        const body = res.headers.get("content-type")?.includes("json")
          ? await res.clone().json()
          : await res.clone().text();
        throw createError(res.status, body);
      }
      return undefined;
    },
  };
}

export function useFetchClientAuth<Paths extends object>() {
  const { token, setToken } = useAuth();

  return useMemo(() => {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const client = createClient<Paths>({
      baseUrl: BASE_URL,
      headers,
    });

    client.use(throwOnError(setToken));

    return client;
  }, [token, setToken]);
}

export function useFetchClient<Paths extends object>() {
  return useMemo(() => {
    const client = createClient<Paths>({ baseUrl: BASE_URL });

    client.use(throwOnError());

    return client;
  }, []);
}

// Decode error
