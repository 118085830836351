import { useEffect } from "react";
import { DefaultError, UseMutationOptions, useMutation } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { z } from "zod";

import type { paths } from "../gen/api/file";
import { MutationOptions, useFetchClientAuth } from "./rpc/client";
import * as rpcFile from "../rpc/file";
import { newFetchClient } from "../rpc/utils";
import { useWebsocketUpdates } from "../rpc/websocket";
import { must } from "../util/assert";

export function useUploadUrl(params?: MutationOptions<paths["/file/upload"]["post"]>) {
  const client = useFetchClientAuth<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/file/upload", { body });

      return must(data);
    },
    ...params,
  });
}

const client = newFetchClient();

export function useUploadSend(params?: UseMutationOptions<unknown, DefaultError, { url: string; file: File }>) {
  return useMutation({
    async mutationFn({ url, file }: { url: string; file: File }) {
      await client.PUT_FILE<rpcFile.UploadFileResponse>(url, file);
    },
    ...params,
  });
}

export function useFileListener(action: (id: string, error?: string) => void) {
  const { addListener } = useWebsocketUpdates();

  useEffect(
    () =>
      addListener("file", (event: z.infer<typeof rpcFile.FileEventSchema>) => {
        if (event.action === "error") {
          if (event.body && event.body.id) {
            action(event.body.id, event.body?.error);
          }
          Sentry.captureEvent({
            message: "useFileListener error",
            level: "error",
            extra: {
              id: event.body?.id,
              body: event.body?.error,
            },
          });
        } else if (event.action === "create") {
          if (event.body && event.body.id) {
            action(event.body.id);
          }
          Sentry.captureEvent({
            message: "useFileListener create error",
            level: "error",
            extra: {
              id: event.body?.id,
              body: event.body?.error,
            },
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
