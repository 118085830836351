import { useMutation } from "@tanstack/react-query";

import type { paths } from "../gen/api/gpt";
import { useFetchClientAuth, MutationOptions } from "./rpc/client";
import { must } from "../util/assert";

export function useTextApi(params?: MutationOptions<paths["/gpt/create"]["post"]>) {
  const client = useFetchClientAuth<paths>();

  return useMutation({
    async mutationFn({ body }) {
      const { data } = await client.POST("/gpt/create", { body });

      return must(data);
    },
    ...params,
  });
}
