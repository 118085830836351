import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthLogout } from "../../hooks/auth";

export function AuthLogoutPage() {
  const navigate = useNavigate();
  const logout = useAuthLogout({
    onSuccess() {
      navigate("/auth/login");
    },
  });

  useEffect(logout);

  return null;
}
