import React from "react";
import { BsTypeItalic } from "react-icons/bs";
import { WithEditor } from "../types";
import ToolbarButton from "./ToolbarButton";

export default function ItalicTool({ editor }: WithEditor) {
  return (
    <ToolbarButton
      label="Italic"
      onClick={() => editor.chain().focus().toggleItalic().run()}
      isActive={editor.isActive("italic")}
      icon={<BsTypeItalic />}
      disabled={!editor.isEditable}
    >
      I
    </ToolbarButton>
  );
}
